import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
  Hidden,
} from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LockIcon from "@material-ui/icons/Lock";
import imgLogo from "../dglogo.png";
import AlertSnackComponent from "./AlertSnackComponent";
import { useAuth, useFirebaseApp } from "reactfire";
import BackdropComponent from "./BackdropComponent";
import firebase from "firebase";

function LoginComponent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setalertMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const loginHandler = async () => {
    setLoading(true);
    if (password.length === 0) {
      setalertMessage("Please enter login details");
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    // Login
    await auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return auth
          .signInWithEmailAndPassword(email, password)
          .then((r) => {
            setLoading(false);
            return;
          })
          .catch((e) => {
            let msg = "";
            switch (e.code) {
              case "auth/user-not-found":
              case "auth/invalid-email":
              case "auth/wrong-password":
                msg = "Incorrect email or password";
                break;

              // @TODO add other error codes here

              default:
                // console.log(e.code);
                msg = "Error, please try again later";
                break;
            }

            setalertMessage(msg);
            setOpenSnack(true);
            setLoading(false);
            return;
          });
      })
      .catch((e) => {
        setalertMessage(e.message);
        setOpenSnack(true);
      });
  };

  const forgotPasswordHandler = async () => {
    setLoading(true);
    if (email.length === 0) {
      setalertMessage("Please enter email address");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    await auth.sendPasswordResetEmail(email).catch((e) => {
      console.log(e);
      let msg = "";
      switch (e.code) {
        case "auth/argument-error":
          msg = "Error, please try again";
          break;

        default:
          msg = "Error, please try again";
      }
      setalertMessage(msg);
      setOpenSnack(true);
      setLoading(false);
      return;
    });
    setLoading(false);
  };

  return (
    <>
      <AlertSnackComponent
        alertMessage={alertMessage}
        openSnack={openSnack}
        closeSnack={() => setOpenSnack(false)}
      />
      <Grid
        // direction="column"
        // justify="center"
        // alignItems="center"
        style={{ padding: 20, float: "right" }}
        sm={4}
      >
        <BackdropComponent loading={loading} />
        <img
          src={imgLogo}
          width={200}
          alt="Dutton one logo"
          style={{ marginTop: 30 }}
        />

        <Typography variant="h5">Auction Login</Typography>
        <TextField
          required
          fullWidth
          label="Email"
          placeholder="user@duttongroup.com.au"
          variant="outlined"
          type="email"
          name="user"
          onChange={(e) => setEmail(e.target.value)}
          className="loginfield"
          style={{ marginTop: 10 }}
        />
        <TextField
          required
          fullWidth
          className="loginfield"
          label="Password"
          name="password"
          placeholder="******"
          variant="outlined"
          type="password"
          style={{ marginTop: 10 }}
          onChange={(e) => setPassword(e.target.value)}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <LockIcon />
          //     </InputAdornment>
          //   ),
          // }}
        />
        <Button
          onClick={loginHandler}
          disabled={loading}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 10 }}
        >
          Sign in
        </Button>
        <Button
          onClick={forgotPasswordHandler}
          disabled={loading}
          variant="outlined"
          color="secondary"
          fullWidth
          style={{ marginTop: 10 }}
        >
          Forgot password
        </Button>
        <br />
        <br />
        <hr />
        <br />
        <Typography>
          This application is restricted for its intended audience only.
          Unauthorized access is forbidden by law.
        </Typography>
        <br />
        <Typography>
          Any bids submitted using this system are binding and the respective
          user will be required to complete any and all transactions related to
          the binding bid.
        </Typography>
        <br />
        <Typography>Developed by Dutton Group Pty Ltd.</Typography>
      </Grid>
    </>
  );
}

export default LoginComponent;
