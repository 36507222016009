import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { FirebaseAppProvider, SuspenseWithPerf, AuthCheck } from "reactfire";
import Main from "./components/Main";
import { firebaseConfig } from "./config/firebase-config";
import BackdropComponent from "./components/BackdropComponent";
import LoginComponent from "./components/LoginComponent";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a67d03414eba22f5cb775733cf068588@o1334655.ingest.sentry.io/4505910131425280",
  integrations: [new Sentry.Replay()],
  environment: process.env.NODE_ENV,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SuspenseWithPerf
        fallback={<BackdropComponent loading={true} />}
        traceId={"load-backdrop-status"}
      >
        <AuthCheck
          fallback={<LoginComponent />}
          // requiredClaims={{
          //   admin: false,
          //   freight_c: false,
          //   freight_cru: false,
          //   insights: false,
          // }}
        >
          {/* <RouterComponent /> */}
          <Main />
        </AuthCheck>
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  );
}

export default App;
