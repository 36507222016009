/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";

export default function SearchComponent({ filterHandle, clearFilter }) {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    // const searchVal = e.target.value;
    setSearchValue(e.target.value);
  };

  const clear = () => {
    setSearchValue("");
    clearFilter();
  };
  return (
    <div style={{ width: "auto", marginBottom: 20, display: "flex" }}>
      <TextField
        // {...params}
        fullWidth
        label="Search by stock id"
        margin="normal"
        variant="outlined"
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            filterHandle(searchValue);
          }
        }}
        value={searchValue}
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={clear} position="end">
              <ClearIcon />
            </InputAdornment>
          ),
        }}
        inputMode="numeric"
        pattern="[0-9]*"
        type="tel"
      />
      <Button onClick={() => filterHandle(searchValue)}>
        <SearchIcon /> Click to search
      </Button>
    </div>
  );
}
