import { useState, useEffect } from "react";
import { useFirestore } from "reactfire";
import dayjs from "dayjs";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

function useAuction() {
  const [currentAuction, setCurrentAuction] = useState({ auctionId: 0 });

  const db = useFirestore();

  useEffect(() => {
    const unsub = db
      .collection("currentAuction")
      .doc("auctionInfo")
      .onSnapshot((doc) => {
        // console.log("fetching");
        if (doc.exists) {
          // console.log("Current auction:", doc.data());
          const data = doc.data();
          // Get auction data
          db.collection("auctions")
            .doc(data.auctionId)
            .onSnapshot((adoc) => {
              if (adoc.exists) {
                const adata = adoc.data();
                const timenow = dayjs().unix();

                const auctionEnd = adata.timestampEnd < timenow ? true : false;
                const auctionStart =
                  adata.timestampStart <= timenow ? true : false;
                setCurrentAuction({
                  ...data,
                  ...adata,
                  auctionEnd: auctionEnd,
                  auctionStart: auctionStart,
                });
              }
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No auction available");
          setCurrentAuction({ auctionId: 0 });
        }
      });
    return () => unsub;
  }, []);

  return currentAuction;
}

export default useAuction;
