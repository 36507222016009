import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { Button, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export const showAlert = (message, open, setOpenSnack) => {
  return (
    <AlertSnackComponent
      alertMessage={message}
      openSnack={open}
      closeSnack={setOpenSnack}
    />
  );
};

function AlertSnackComponent({ alertMessage, openSnack, closeSnack }) {
  // const [open, setOpenSnack] = useState(openSnack);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnack();
  };

  // useEffect(() => {
  //   setOpenSnack(openSnack);
  // }, [openSnack]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={openSnack}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert severity="warning">{alertMessage}</Alert>
    </Snackbar>
  );
}

export default AlertSnackComponent;
