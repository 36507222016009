import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

function BackdropComponent({ loading }) {
  return (
    <Backdrop open={loading} style={{ color: "#dgdgdg", zIndex: 9999 }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

export default BackdropComponent;
