import { useState, useEffect } from "react";
import { useFirestore } from "reactfire";
import useAuction from "./useAuction";

function useVehicles() {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const db = useFirestore();
  const auction = useAuction();

  useEffect(() => {
    // console.log("Loading vehicles", auction);
    if (auction.auctionId !== 0) {
      setLoading(true);
      const auctionId = auction.auctionId;
      db.collection("auctions")
        .doc(auctionId)
        .collection("vehicles")
        .get()
        .then((querySnapshot) => {
          const veh = [];
          // console.log("Vehicles found", querySnapshot.size);
          querySnapshot.forEach(function (doc) {
            const d = doc.data();
            const keys = Object.keys(d);
            let carDetails = keys.map((k) => {
              if (k === "thumbs") {
                return false;
              } else {
                return { title: k, value: d[k] };
              }
            });

            // Sort details by title alphabetically
            carDetails.sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            });
            const description = `${d["Description"]}`;

            const vehTmp = {
              id: d["Stock No"],
              rego: d.Rego,
              title: description,
              odo: d.Odometer,
              subtitle: d["Variant & Series"],
              // price: d["Inventory Balance (Ex)"],
              reserve: d["Reserve"],
              // bids: [],
              description: description,
              details: carDetails,
              thumbs: d.thumbs,
              dealership: d.Branch.trim(),
              colour: d.Colour,
              model: d.Model,
              buildYear: d["Year"],
            };
            veh.push(vehTmp);
          });
          // console.log(veh[0]);
          setVehicles(veh);
          setLoading(false);
        });
    }
  }, [auction.auctionId]);

  return { vehicles, loading };
}

const car1 = {
  id: 5000,
  rego: "AXX123",
  title: "2019 BMW X5",
  subtitle: "RED Auto 6sp",
  price: 85000,
  bids: [
    //   { bidPrice: 86000, timestamp: moment().fromNow() }
  ],
  description: "2016 BMW X5 F15 SDRIVE25D WAGON 5DR AUTO 8SP 2.0DTT",
  details: [
    {
      title: "KILOMETERS",
      value: "79,868",
    },
    {
      title: "TRANSMISSION",
      value: "AUTOMATIC",
    },
  ],
  thumbs: [
    {
      title: "hero",
      src: "https://virtualyard.com.au/vydata/c3c59e5f8b3e9753913f4d435b53c308//1fb3826cc22b480b08eb7556856503fe.jpg",
    },
  ],
};

const car2 = {
  id: 6000,
  rego: "BYY321",
  title: "2018 Volvo XC90",
  subtitle: "BLACK Auto 4sp",
  price: 71000,
  bids: [
    // { bidPrice: 72000, timestamp: moment().fromNow() },
    // { bidPrice: 73000, timestamp: moment().fromNow() },
    // { bidPrice: 74000, timestamp: moment().fromNow() },
    // { bidPrice: 75400, timestamp: moment().fromNow() },
    // { bidPrice: 76000, timestamp: moment().fromNow() },
    // { bidPrice: 77000, timestamp: moment().fromNow() },
    // { bidPrice: 73000, timestamp: moment().fromNow() },
    // { bidPrice: 73000, timestamp: moment().fromNow() },
    // { bidPrice: 73000, timestamp: moment().fromNow() },
    // { bidPrice: 73000, timestamp: moment().fromNow() },
    // { bidPrice: 73000, timestamp: moment().fromNow() },
  ],
  description:
    "2018 VOLVO XC90 T6 INSCRIPTION WAGON 7ST 5DR GEARTRONIC 8SP AWD 2.0TSC [MY19]",
  details: [
    {
      title: "KILOMETERS",
      value: "36,209",
    },
    {
      title: "TRANSMISSION",
      value: "SPORTS AUTOMATIC",
    },
  ],
  thumbs: [
    {
      title: "hero",
      src: "https://virtualyard.com.au/vydata/c3c59e5f8b3e9753913f4d435b53c308//9c221b74e6c2f3fb3406824ce957ebc2.jpg",
    },
  ],
};
export default useVehicles;
