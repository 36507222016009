import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import useWindowDimensions from "./hooks/useWindowDImensions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#000",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 600,
    // height: 400,
  },
}));

export default function ImageViewComponent({ tileData, open, close }) {
  const classes = useStyles();

  const handleClose = () => {
    close();
  };

  const { height, width } = useWindowDimensions();

  if (width < 400) {
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullScreen
          onClose={handleClose}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                cols={1}
                cellHeight="auto"
                spacing={1}
              >
                {tileData.map((tile, idx) => (
                  <GridListTile key={idx} cols={tile.cols || 1}>
                    <img
                      src={tile.src}
                      alt={tile.title}
                      height="auto"
                      width="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Dialog
          open={open}
          fullScreen
          onClose={handleClose}
          scroll="paper"
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                cols={2}
                cellHeight={400}
                spacing={3}
              >
                {tileData.map((tile, idx) => (
                  <GridListTile key={idx} cols={tile.cols || 2}>
                    <img
                      src={tile.src + "?crop=0"}
                      alt={tile.title}
                      height={400}
                      width={600}
                      style={{ objectFit: "contain" }}
                    />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            {/* <Button onClick={handleClose} color="primary">
              Agree
            </Button> */}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
