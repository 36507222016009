import React, { useState, useEffect } from "react";
import CarComponent from "./CarComponent";
import Grid from "@material-ui/core/Grid";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import SearchComponent from "./SearchComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAllBids from "./hooks/useAllBids";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { useUser } from "reactfire";
import BackdropComponent from "./BackdropComponent";
import { ListAltRounded, Timelapse } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import useAuction from "./hooks/useAuction";
import useTimeExtensions from "./hooks/useTimeExtensions";
import dayjs from "dayjs";

function MyBidsComponent() {
  const [myBids, setMyBids] = useState([]);

  const user = useUser();
  const { bids, loading } = useAllBids();
  const navigate = useNavigate();
  const currentAuction = useAuction();
  const { timeExtensions } = useTimeExtensions();

  const getHighestBid = (carId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return (
          <NumberFormat
            value={carBids[0].bid}
            displayType="text"
            thousandSeparator={true}
            prefix={"$"}
          />
        );
      } else {
        return "---";
      }
    }
  };

  const getCellColorIfCurrentUserIsHighestBidder = (carId, bidId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return carBids[0].id === bidId ? "green" : "red";
      } else {
        return null;
      }
    }
  };

  const getUserNameFromUid = (uid) => {
    const member = currentAuction?.members?.find((m) => m.uid === uid);
    return member?.userName || "";
  };

  const getHighestBidder = (carId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return carBids[0].userEmail === user.email
          ? "Me"
          : getUserNameFromUid(carBids[0].user);
      } else {
        return "---";
      }
    }
  };

  const getHighestBidTime = (carId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return <Moment date={carBids[0]?.locTime} fromNow />;
      } else {
        return "---";
      }
    }
  };

  const getTimeExtensions = (carId) => {
    if (timeExtensions) {
      // Filter car
      const te = timeExtensions?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      te.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (te.length > 0) {
        const extendedTo = currentAuction?.timestampEnd + te?.length * 60 * 3;
        return (
          <Chip
            icon={<Timelapse fontSize="small" />}
            label={`Time extended to: ${dayjs
              .unix(extendedTo)
              .format("HH:mm:ss")}`}
            color="secondary"
            variant="outlined"
            style={{ marginBottom: 5 }}
          />
        );
      } else {
        return "---";
      }
    }
  };

  useEffect(() => {
    // Filter for current user's bids
    if (bids.length > 0) {
      const tmpBids = bids.filter((b) => b.user === user.uid);
      setMyBids(tmpBids);
    } else {
      setMyBids([]);
    }
  }, [JSON.stringify(bids)]);

  return (
    <>
      <BackdropComponent loading={loading} />
      <Grid
        container
        // spacing={1}
        direction="column"
        // justify="center"
        alignItems="center"
      >
        <Grid item style={{ marginBottom: 20, marginTop: 20 }}>
          <Typography variant="h5">My bids</Typography>
        </Grid>
        <Paper sx={{ width: "100%", overflow: "scroll" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Stock#</TableCell>
                <TableCell>Description</TableCell>
                {/* <TableCell>Colour</TableCell>
                <TableCell>Build Year</TableCell> */}
                <TableCell>My Bid</TableCell>
                <TableCell>Current Highest Bid</TableCell>
                <TableCell>Bidder</TableCell>
                <TableCell>Last bid @</TableCell>
                <TableCell>TE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myBids.map((bid, idx) => {
                return (
                  <TableRow
                    hover
                    key={idx}
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/item/${currentAuction.auctionId}/${bid.carId}`)
                    }
                  >
                    <TableCell>
                      <ListAltRounded
                        onClick={() =>
                          navigate(
                            `/item/${currentAuction.auctionId}/${bid.carId}`
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{bid.carId}</TableCell>
                    <TableCell>{bid.title}</TableCell>
                    {/* <TableCell>{bid.colour}</TableCell>
                      <TableCell>{bid.buildYear}</TableCell> */}
                    <TableCell
                      style={{
                        color: getCellColorIfCurrentUserIsHighestBidder(
                          bid.carId,
                          bid.id
                        ),
                      }}
                    >
                      <NumberFormat
                        value={bid.bid}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                    <TableCell>{getHighestBid(bid.carId)}</TableCell>
                    <TableCell>{getHighestBidder(bid.carId)}</TableCell>
                    <TableCell>{getHighestBidTime(bid.carId)}</TableCell>
                    <TableCell>{getTimeExtensions(bid.carId)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <div style={{ marginBottom: 75 }}></div>
    </>
  );
}

export default MyBidsComponent;
