import { useState, useEffect } from "react";
import { useFirestore } from "reactfire";
import useAuction from "./useAuction";

function useAllBids() {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);

  const db = useFirestore();
  const currentAuction = useAuction();

  useEffect(() => {
    if (currentAuction.auctionId !== 0) {
      setLoading(true);
      const auctionId = currentAuction.auctionId;
      const unsuscribeBids = db
        .collection("auctions")
        .doc(auctionId)
        .collection("bids")
        // .where("carId", "==", parseInt(carId))
        .orderBy("serverTime", "desc")
        .onSnapshot(function (querySnapshot) {
          var tmpBids = [];
          querySnapshot.forEach(function (doc) {
            const tmpData = doc.data();
            tmpBids.push({ id: doc.id, ...tmpData });
          });
          setBids(tmpBids);
          setLoading(false);
        });

      return () => {
        unsuscribeBids();
        setLoading(false);
      };
    }
  }, [currentAuction.auctionId]);

  return { bids, loading };
}

export default useAllBids;
