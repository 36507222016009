import React, { useState } from "react";
import {
  Button,
  Typography,
  FormHelperText,
  TextField,
  Grid,
} from "@material-ui/core";
import { useFirestore, useFunctions } from "reactfire";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackdropComponent from "./BackdropComponent";
import dayjs from "dayjs";
import useAllBids from "./hooks/useAllBids";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

// import { vehicles } from "../av1711";
// const vehicles = require("./av100321.json")
// import { vyezyveh } from "../vyezyveh";
const vehicles = require("../av190923.json");
const dcmerged = require("../vehicles.json");

function CreateNewAuctionComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const [startAuction, setStartAuction] = useState("");
  const [endAuction, setEndAuction] = useState("");
  const [stockId, setStockId] = useState("");
  const [photo1, setPhoto1] = useState("");
  const [photo2, setPhoto2] = useState("");
  const [photo3, setPhoto3] = useState("");
  const [photo4, setPhoto4] = useState("");
  const [photo5, setPhoto5] = useState("");
  const [exportFileUrl, setExportFileUrl] = useState("");

  const db = useFirestore();
  const functions = useFunctions();
  const { bids } = useAllBids();

  const updatePhotosToStockId = async () => {
    setIsLoading(true);
    // get current auction
    const currentAuction = await db
      .collection("currentAuction")
      .doc("auctionInfo")
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          return doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          return null;
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return null;
      });
    // if current auction info is retreivied
    // then update photos
    console.log(currentAuction);
    if (currentAuction) {
      // Set up photo payload
      const thumbs = [];

      if (photo1.length === 0 || stockId.length === 0) {
        return;
      }
      if (photo1.length > 0) {
        thumbs.push({ src: photo1, title: "hero" });
      }
      if (photo2.length > 0) {
        thumbs.push({ src: photo2, title: "hero" });
      }
      if (photo3.length > 0) {
        thumbs.push({ src: photo3, title: "hero" });
      }
      if (photo4.length > 0) {
        thumbs.push({ src: photo4, title: "hero" });
      }
      if (photo5.length > 0) {
        thumbs.push({ src: photo5, title: "hero" });
      }

      console.log(thumbs);
      await db
        .collection("auctions")
        .doc(currentAuction.auctionId)
        .collection("vehicles")
        .doc(stockId)
        .set({ thumbs: thumbs }, { merge: true })
        .then((r) => {
          console.log("Updated");
        });
    }
    setIsLoading(false);
  };

  const exportToCsv = async () => {
    if (bids) {
      const formattedBids = bids.map((b) => {
        return {
          id: b.id,
          stockNo: b.carId,
          bid: b.bid,
          user: b.user,
          rego: b.carRego,
          bidTime: b.locTimeEpoch,
          auctionId: b.auctionId,
        };
      });
      var json = JSON.stringify(formattedBids);
      // var csv = [];
      // if (formattedBids.length) {
      //   var keys = Object.keys(formattedBids[0]);
      //   csv.push(keys.join(","));
      //   formattedBids.forEach((item) => {
      //     let vals = keys.map((key) => item[key] || "");
      //     csv.push(vals.join(","));
      //   });
      // }

      // csv = csv.join("\r\n");
      // var json = JSON.stringify(csv);

      //Convert JSON string to BLOB.
      json = [json];
      var blob1 = new Blob(json, { type: "text/plain;charset=utf-8" });
      const NT = dayjs().unix();
      var url = window.URL || window.webkitURL;

      const fname = `bids_${NT}.json`;
      const link = url.createObjectURL(blob1);
      setExportFileUrl(link);
    }
  };

  const getHeroThumb = async (stockId) => {
    console.log("Getting images if available", stockId);

    stockId = stockId.toString();
    // const branch = "duttonone";
    var vyVeh = dcmerged.find((v) => v?.supplierStockNo === stockId);

    // console.log(vyVeh);
    // return;

    // vyVeh = vyezyveh.find((e) => {
    //   if (e !== null) {
    //     if (typeof e.supplierStockNo !== "undefined" || e !== null) {
    //       if (e.supplierStockNo === stockId) return true;
    //     }
    //   }
    //   return false;
    // });

    if (vyVeh) {
      console.log("found images");
      const photoPath = vyVeh.photoPath;
      const pics = vyVeh.nopics;

      // const keys = Object.keys(vyVeh);
      // const thumbKeys = keys.filter((k) => {
      //   if (k.slice(0, 5) === "thumb") {
      //     return true;
      //   }
      //   return false;
      // });

      const thumbs = [];

      for (let index = 1; index < pics; index++) {
        // const k = thumbKeys[index];
        // const thumbHero = vyVeh[k];
        const field = `photo${index}`;
        const heroThumbUrl = photoPath + vyVeh[field];
        const payload = { title: "hero", src: heroThumbUrl };
        thumbs.push(payload);
      }

      return thumbs;
    } else {
      return [];
    }
  };

  const createNewAuction = async () => {
    setIsLoading(true);
    const payload = {
      members: [
        {
          uid: "odANpxBpWRYCkmksufBqkuzEdwv2",
          ownBranches: [],
          userName: "Juv",
        }, //Juv
        {
          uid: "SQGXVaQKZ2hNvlWUKdVMLegptvz2",
          ownBranches: [],
          userName: "Summer",
        },
        {
          uid: "qcKVf0Ua05ba5rmejlKWlJmmMC92",
          ownBranches: [],
          userName: "Moritz",
        },
        {
          uid: "TuY8jTAjonNXCmDL5mmHVVpYJP73",
          ownBranches: [
            "D1 BRISBANE",
            "D1 SPRINGWOOD",
            "D1 WINDSOR",
            "D1 GOLD COAST",
          ],
          userName: "Greg Lipton",
        }, //Greg Lipton
        {
          uid: "YbiYAXlh96QeEGDDk7mh6Zb0YjY2",
          ownBranches: [
            "D1 MASCOT",
            "D1 CHATSWOOD",
            "D1 BROOKVALE",
            "D1 CANBERRA",
            "D1 FYSHWICK",
            "D1 CARINGBAH",
          ],
          userName: "Stu Codling",
        }, //Stu Codling
        {
          uid: "BxWFExV0lgcrNAN5LKjJWy1aTgh2",
          ownBranches: ["SYCF RICHMOND"],
          userName: "Chris Wood",
        }, //Chris Wood
        {
          uid: "40dJyQH2jaSsaaqKkXAYHvsO8Op2",
          ownBranches: ["SYCF RICHMOND"],
          userName: "Nick Germanos",
        }, //Nick Germanos

        {
          uid: "yYfTVNgpTHUVDOv8qN06Mal5t6G3",
          ownBranches: ["WS VIC", "WS QLD"],
          userName: "Cameron Pascoe",
        }, //Cameron Pascoe

        {
          uid: "xBiHJwG8KOPFHnJPIKtGgzJIA783",
          ownBranches: ["DG BUYING SERVICE"],
          userName: "Chris Fernandez",
        }, //Chris (acting for George)

        {
          uid: "TWZ7XcS1wufHu2CM4eHgCEWct1H3",
          ownBranches: ["D1 ADELAIDE"],
          userName: "Dan Moss",
        }, //Dan Moss
        {
          uid: "zbBUj2U4uvbXXkLAS0DTwFaMbYT2",
          ownBranches: [
            "AMW - ALTONA",
            "AMW - MELBOURNE",
            "AMW - BRISBANE",
            "AMW - PLYMPTON",
            "AMW - DANDENONG",
            "AMW - FAIRFIELD",
          ],
          userName: "Darren Soppi",
        }, //Darren soppi
        {
          uid: "o32B1sKenpc9U7q9Ne0JhoRls0a2",
          ownBranches: [
            "AMW - ALTONA",
            "AMW - MELBOURNE",
            "AMW - BRISBANE",
            "AMW - PLYMPTON",
            "AMW - DANDENONG",
            "AMW - FAIRFIELD",
          ],
          userName: "Dil",
        }, //Dil
        {
          uid: "GYCVLEaFhfYDzvjFgqqFZ6ClO1H3",
          ownBranches: [],
          userName: "Gez",
        }, //Gez
        {
          uid: "6UGTmDWoIuZjQ2HXOiIYnVQs7jr1",
          ownBranches: ["SYCF RICHMOND"],
          userName: "Vaughan Smith",
        }, //Vaughn Smith
        {
          uid: "zu2hr5qd4YXlJ5TjIdddXmRu4BO2",
          ownBranches: ["WS VIC", "WS QLD"],
          userName: "Robert Klancic",
        }, //Robert Klancic
      ],
      timestampStart: startAuction,
      timestampEnd: endAuction,
      timeExtensionAvailable: true,
    };
    // Set new auction
    const newAuctionId = await db
      .collection("auctions")
      .add(payload)
      .then((docRef) => {
        return docRef.id;
      })
      .catch();

    // Load cars into new auction
    // Get a new write batch
    var batch = db.batch();
    // Set ref pointer
    var newAuctionRef = db
      .collection("auctions")
      .doc(newAuctionId)
      .collection("vehicles");

    for (let index = 0; index < vehicles.length; index++) {
      var v = vehicles[index];
      const thumbs = await getHeroThumb(v["Stock No"]);

      v = { thumbs, ...v };
      var newAuctionRef = db
        .collection("auctions")
        .doc(newAuctionId)
        .collection("vehicles")
        .doc(v["Stock No"]);
      // Batch vehicles
      batch.set(newAuctionRef, v);
    }
    // Commit the batch
    await batch
      .commit()
      .then(async function () {
        await db
          .collection("currentAuction")
          .doc("auctionInfo")
          .set({ auctionId: newAuctionId }, { merge: true });
        alert(`created new auction with id ${newAuctionId}`);
      })
      .catch((e) => {
        alert(e.message);
      });
    setIsLoading(false);
  };

  return (
    <div>
      <BackdropComponent loading={isLoading} />
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid>
          <Button onClick={exportToCsv}>Export bids</Button>
          Download:
          <a href={exportFileUrl} download={`bids_${dayjs().unix()}.json`}>
            Click here
          </a>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Create new auction</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <TextField
              label="Auction Start Date/Time"
              type="datetime-local"
              // defaultValue={moment()}
              //   className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const dconvert = dayjs(
                  e.target.value,
                  "YYYY-MM-DDTHH:mm"
                ).unix();
                console.log(dconvert);
                setStartAuction(dconvert);
              }}
              variant="outlined"
            />
            <FormHelperText>
              Choose the start date and time for the auction
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <TextField
              label="Auction End Date/Time"
              type="datetime-local"
              // defaultValue={moment()}
              //   className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const dconvert = dayjs(
                  e.target.value,
                  "YYYY-MM-DDTHH:mm"
                ).unix();
                console.log(dconvert);
                setEndAuction(dconvert);
              }}
              variant="outlined"
            />
            <FormHelperText>
              Choose the end date and time for the auction
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={<Checkbox color="primary" />}
                label="Load cars from json"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid>{isLoading ? <CircularProgress /> : null}</Grid>
        <Grid item xs={12}>
          <Button onClick={createNewAuction} variant="outlined">
            Create New Auction
          </Button>
        </Grid>
        <Grid>
          {/* <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Add (replace) photos</FormLabel>
              <FormGroup aria-label="position" column="true">
                <TextField
                  style={{ paddingTop: 5 }}
                  id="outlined-stockId"
                  label="stockId"
                  value={stockId}
                  variant="outlined"
                  onChange={(e) => {
                    setStockId(e.target.value);
                  }}
                />
                <TextField
                  style={{ paddingTop: 5 }}
                  id="outlined-photo1"
                  label="photo1"
                  value={photo1}
                  variant="outlined"
                  onChange={(e) => {
                    setPhoto1(e.target.value);
                  }}
                />
                <TextField
                  style={{ paddingTop: 5 }}
                  id="outlined-photo2"
                  label="photo2"
                  value={photo2}
                  variant="outlined"
                  onChange={(e) => {
                    setPhoto2(e.target.value);
                  }}
                />
                <TextField
                  style={{ paddingTop: 5 }}
                  id="outlined-photo3"
                  label="photo3"
                  value={photo3}
                  variant="outlined"
                  onChange={(e) => {
                    setPhoto3(e.target.value);
                  }}
                />

                <TextField
                  style={{ paddingTop: 5 }}
                  id="outlined-photo4"
                  label="photo4 (optional)"
                  value={photo4}
                  variant="outlined"
                  onChange={(e) => {
                    setPhoto4(e.target.value);
                  }}
                />
                <TextField
                  style={{ paddingTop: 5 }}
                  id="outlined-photo5"
                  label="photo5 (optional)"
                  value={photo5}
                  variant="outlined"
                  onChange={(e) => {
                    setPhoto5(e.target.value);
                  }}
                />
                <Button
                  style={{ marginTop: 15, marginBottom: 90 }}
                  onClick={() => {
                    updatePhotosToStockId();
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Update photos for stock ID
                </Button>
              </FormGroup>
            </FormControl>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateNewAuctionComponent;
