import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Button, Typography, CircularProgress, Grid } from "@material-ui/core";
import BackdropComponent from "./BackdropComponent";
import useVehicle from "./hooks/useVehicle";
import CarComponent from "./CarComponent";
import useAllBids from "./hooks/useAllBids";
import useAuction from "./hooks/useAuction";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const CheckingAuctionStatus = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          Checking vehicle status... <CircularProgress size={20} />
        </Grid>
      </Grid>
    </div>
  );
};

function ViewItemComponent() {
  const [checkingAuctionStatus, setCheckingAuctionStatus] =
    React.useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const currentAuction = useAuction();

  const auctionId = params.auctionId;
  const itemId = params.itemId;

  const { car, loading, error } = useVehicle(auctionId, itemId);
  const { bids } = useAllBids();

  useEffect(() => {
    if (
      auctionId !== currentAuction.auctionId &&
      currentAuction.auctionId !== 0 &&
      currentAuction.auctionEnd === false &&
      currentAuction.auctionStart === true
    ) {
      console.log(currentAuction);
      navigate("/not-found");
      console.log("Auction ID mismatch, redirecting to home page");
    } else {
      setCheckingAuctionStatus(false);
    }
  }, [auctionId, currentAuction]);

  useEffect(() => {
    if (error) {
      navigate("/not-found");
      console.log("Vehicle not found, redirecting to home page");
    }
  }, [error]);

  return (
    <>
      <BackdropComponent loading={loading || checkingAuctionStatus} />
      {checkingAuctionStatus || loading ? (
        <CheckingAuctionStatus />
      ) : (
        <>
          <Grid
            container
            // spacing={1}
            direction="column"
            // justify="center"
            alignItems="center"
          >
            <Grid item style={{ marginBottom: 20, marginTop: 20 }}>
              <Typography variant="h5">View vehicle</Typography>
            </Grid>
          </Grid>
          <br />
          {/* <Button onClick={() => navigate("/current-auction-table")}>
        Back to list
      </Button> */}
          <CarComponent car={car} allBids={bids} />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </>
  );
}

export default ViewItemComponent;
