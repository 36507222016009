import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CarInfoDialogComponent from "./CarInfoDialogComponent";
import BidsDialogComponent from "./BidsDialogComponent";
import blockUrl from "../blockImg.jpg";
import ImageViewComponent from "./ImageViewerComponent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import { Grid, Hidden } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import Chip from "@material-ui/core/Chip";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GavelIcon from "@material-ui/icons/Gavel";
import useTimeExtension from "./hooks/useTimeExtension";
import { Timelapse } from "@material-ui/icons";
import dayjs from "dayjs";
import useAuction from "./hooks/useAuction";
import { useFirestore } from "reactfire";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 250,
    // width: "100%",
    marginLeft: 12,
    marginRight: 12,
    padding: 10,
  },
}));

function CarComponent({ car, allBids }) {
  const classes = useStyles();
  const theme = useTheme();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openBids, setOpenBids] = React.useState(false);
  const [showImageViewer, setShowImageViewer] = React.useState(false);
  const [hasHighestBid, setHasHighestBid] = React.useState(false);
  const [timeExtensionInPlay, setTimeExtensionInPlay] = React.useState(false);
  const [timeExtensionInSeconds, setTimeExtensionInSeconds] = React.useState(0);

  const currentAuction = useAuction();
  const { timeExtensions } = useTimeExtension(car.id);

  const getHighestBid = (carId) => {
    if (allBids) {
      // Filter car
      const carBids = allBids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return (
          <>
            Highest bid:&nbsp;
            <NumberFormat
              value={carBids[0].bid}
              displayType="text"
              thousandSeparator={true}
              prefix={"$"}
            />
          </>
        );
      } else {
        return false;
      }
    }
  };

  const getHighestBidder = (carId) => {
    if (allBids) {
      // Filter car
      const carBids = allBids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return carBids[0].userEmail;
      } else {
        return "---";
      }
    }
  };

  const closeDetailsDialog = () => {
    setOpenDetails(false);
  };

  const showDetails = () => {
    setOpenDetails(true);
  };

  const closeBidsDialog = () => {
    setOpenBids(false);
  };
  const showBids = () => {
    setOpenBids(true);
  };

  const showImagesDialog = () => {
    setShowImageViewer(true);
  };

  const closeImageViewer = () => {
    setShowImageViewer(false);
  };

  // Set highest bid from list of bids for car
  // Set vehicle bids
  React.useEffect(() => {
    if (allBids) {
      // Filter car
      const carBids = allBids?.filter((b) => {
        return parseInt(b.carId) === parseInt(car.id);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        setHasHighestBid(true);
      } else {
        setHasHighestBid(false);
      }
    }
  }, [JSON.stringify(car), JSON.stringify(allBids)]);

  React.useEffect(() => {
    if (timeExtensions.length > 0) {
      let tmpTE = false;
      const nowTime = dayjs().unix();
      const extendedTo =
        currentAuction.timestampEnd + timeExtensions.length * 60 * 3;
      if (nowTime < extendedTo) {
        setTimeExtensionInPlay(true);
        setTimeExtensionInSeconds(extendedTo);
      } else {
        setTimeExtensionInPlay(false);
        setTimeExtensionInSeconds(0);
      }
    } else {
      setTimeExtensionInPlay(false);
    }
  }, [JSON.stringify(timeExtensions)]);

  return (
    <>
      <CarInfoDialogComponent
        car={car}
        open={openDetails}
        close={closeDetailsDialog}
      />
      <ImageViewComponent
        tileData={car.thumbs}
        close={closeImageViewer}
        open={showImageViewer}
      />

      <BidsDialogComponent
        key={car.id}
        bids={car.bids}
        open={openBids}
        close={closeBidsDialog}
        title={car.title}
        carId={car.id}
        carRego={car.rego}
        car={car}
        allBids={allBids}
      />
      <Card className={classes.root}>
        {/* {JSON.stringify(car,null,2)} */}
        <Grid container>
          <Grid item xs={6}>
            <Grid container direction="row">
              <Chip
                icon={<LocationOnIcon />}
                label={car.dealership}
                color="secondary"
                style={{ marginBottom: 5 }}
              />
              &nbsp;&nbsp;
              {hasHighestBid ? (
                <Chip
                  icon={<GavelIcon fontSize="small" />}
                  label={getHighestBid(car.id)}
                  color="primary"
                  style={{ marginBottom: 5 }}
                />
              ) : (
                <Chip label="No bids yet" variant="outlined" />
              )}
              &nbsp;
              {timeExtensionInPlay ? (
                <Chip
                  icon={<Timelapse fontSize="small" />}
                  label={`Time extended to: ${dayjs
                    .unix(timeExtensionInSeconds)
                    .format("HH:mm:ss")}`}
                  color="secondary"
                  variant="outlined"
                  style={{ marginBottom: 5 }}
                />
              ) : null}
              {/* <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    color: "white",
                  }}
                > */}
              {/* Car hero image (insert carousel) */}
              <Grid item xs={12}>
                <Carousel indicators={false} autoPlay={false} animation="fade">
                  {/* {car.thumbs.map((th, index) => { */}
                  {/* return ( */}
                  <CardMedia
                    component="img"
                    key={`img11`}
                    image={
                      typeof car?.thumbs[0] === "undefined"
                        ? blockUrl
                        : car?.thumbs[0]?.src + "?crop=0"
                    }
                    title="hero"
                    height="auto"
                  />
                  {/* ); */}
                  {/* })} */}
                </Carousel>
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image={
                    typeof car?.thumbs[1] === "undefined"
                      ? blockUrl
                      : car?.thumbs[1]?.src + "?crop=0"
                  }
                  title="hero"
                  height="auto"
                />
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image={
                    typeof car?.thumbs[2] === "undefined"
                      ? blockUrl
                      : car?.thumbs[2]?.src + "?crop=0"
                  }
                  title="hero"
                  height="auto"
                />
              </Grid>
              {/* <div style={{ position: "absolute", bottom: 8, right: 20 }}> */}
              {/* </div> */}
            </Grid>
            {/* <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    color: "white",
                  }}
                > */}

            {/* <div style={{ position: "absolute", bottom: 8, right: 20 }}> */}
            <div style={{ cursor: "pointer" }} onClick={showImagesDialog}>
              Click to see more images
            </div>
            {/* </div> */}
          </Grid>
          <Grid item xs={6}>
            <CardContent>
              <Grid style={{ float: "right" }} item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  // color="#000"
                  style={{
                    fontSize: "1.2em",
                    // backgroundColor: "#000",
                    // color: "#FFF",
                  }}
                  aria-label="bids"
                  onClick={showBids}
                >
                  View and place bids
                </Button>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12}>
                  <Typography component="h6" variant="h6">
                    {car && car.title}
                  </Typography>

                  <Typography variant="subtitle1" color="textSecondary">
                    {/* <b>
                          Reserve:
                          <NumberFormat
                            value={car && car.reserve}
                            displayType="text"
                            thousandSeparator={true}
                            prefix=" $"
                          />
                        </b>
                        &nbsp;|| &nbsp; */}
                    <Chip
                      label={
                        <NumberFormat
                          value={car && car.odo}
                          displayType="text"
                          suffix=" kms"
                          thousandSeparator={true}
                        />
                      }
                      color="primary"
                      variant="outlined"
                      style={{ marginBottom: 5 }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Stock#: {car && car.id}
                  </Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item>
                    <Grid container direction="column">
                      <Table size="small" xs={6}>
                        <TableBody>
                          {car?.details?.map((cd, idx) => {
                            return (
                              <TableRow key={idx}>
                                <TableCell>{cd.title}</TableCell>
                                <TableCell>{cd.value}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
        <CardActions>
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <Hidden smUp>
                <IconButton aria-label="info" onClick={showDetails}>
                  <InfoOutlinedIcon style={{ fontSize: "1em" }} />
                  More details
                </IconButton>
              </Hidden>
              {/* <span style={{ color: "green" }}>Latest Bid: $34,000</span> */}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
}

export default CarComponent;
