import { useState, useEffect } from "react";
import { useFirestore } from "reactfire";
import useAuction from "./useAuction";

function useVehicle(auctionId, carId) {
  const [car, setVehicle] = useState({ thumbs: [], details: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const db = useFirestore();
  const currentAuction = useAuction();

  useEffect(() => {
    if (auctionId.length > 0 && carId.length > 0) {
      setLoading(true);
      if (currentAuction && currentAuction.auctionId !== 0) {
        if (auctionId !== currentAuction.auctionId) {
          setError(true);
          setLoading(false);
          return;
        }

        const unsubscribeCar = db
          .collection("auctions")
          .doc(auctionId)
          .collection("vehicles")
          .doc(carId)
          // .where("carId", "==", parseInt(carId))
          // .orderBy("serverTime", "desc")
          .get()
          .then(function (doc) {
            // check if doc exists
            if (!doc.exists) {
              // console.log("No such document!");
              setError(true);
              setLoading(false);
              return;
            }
            const d = doc.data();
            const keys = Object.keys(d);
            let carDetails = keys.map((k) => {
              if (k === "thumbs") {
                return false;
              } else {
                return { title: k, value: d[k] };
              }
            });

            // Sort details by title alphabetically
            carDetails.sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            });
            const description = `${d["Description"]}`;

            const vehTmp = {
              id: d["Stock No"],
              rego: d.Rego,
              title: description,
              odo: d.Odometer,
              subtitle: d["Variant & Series"],
              // price: d["Inventory Balance (Ex)"],
              reserve: d["Reserve"],
              // bids: [],
              description: description,
              details: carDetails,
              thumbs: d.thumbs,
              dealership: d.Branch.trim(),
              colour: d.Colour,
              model: d.Model,
              buildYear: d["Year"],
            };
            // console.log(veh[0]);
            setVehicle(vehTmp);
            setLoading(false);
          });
        return () => unsubscribeCar;
      }
    }
  }, [carId, auctionId, currentAuction]);

  return { car, loading, error };
}

export default useVehicle;
