import React from "react";
import ProtectedMain from "./ProtectedMain";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Link,
} from "react-router-dom";
import BottomNavComponent from "./BottomNavComponent";
import AuctionListComponent from "./AuctionListComponent";
import MyBidsComponent from "./MyBidsComponent";
import { Chip, Grid, Typography } from "@material-ui/core";
import { useUser } from "reactfire";
import AuctionTableComponent from "./AuctionTableComponent";
import WelcomeComponent from "./WelcomeComponent";
import ViewItemComponent from "./ViewItemComponent";
import CreateNewAuctionComponent from "./CreateNewAuctionComponent";
import NotFound from "./NotFoundComponent";

function Main() {
  const user = useUser();
  return (
    <>
      <Grid item style={{ float: "right", paddingRight: 5 }}>
        <Typography>
          <i>
            <sup>Logged in as {user?.email || ""}</sup>
          </i>
        </Typography>
      </Grid>
      <Router>
        <Routes>
          <Route path="/" element={<WelcomeComponent />} />
          <Route
            path="/current-auction-card-view"
            element={<AuctionTableComponent />}
          />
          <Route path="/my-bids" element={<MyBidsComponent />} />
          <Route
            exact
            path="/item/:auctionId/:itemId"
            element={<ViewItemComponent />}
          />
          <Route
            path="/current-auction-table-view"
            element={<AuctionTableComponent />}
          />
          <Route path="/settings" element={<CreateNewAuctionComponent />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
        <BottomNavComponent />
      </Router>
    </>
  );
}

export default Main;
