import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#000",
  },
}));

export default function CarInfoDialogComponent({ car, open, close }) {
  const classes = useStyles();
  const handleClose = () => {
    close();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullScreen
      // scroll="body"
      onClose={handleClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle>{car.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <List className={classes.root}>
            {/* <DialogContentText>{car.description}</DialogContentText> */}
            {car.details.map((cd, idx) => {
              return (
                <ListItem key={idx}>
                  <ListItemText>
                    <b>{`${cd.title}`}</b> : {`${cd.value}`}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
