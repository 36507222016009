import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import GavelIcon from "@material-ui/icons/Gavel";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { useAuth, useUser } from "reactfire";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  AccessTime,
  HomeOutlined,
  LocalAtmOutlined,
  Settings,
} from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import useAuction from "./hooks/useAuction";
import dayjs from "dayjs";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

const KEY_LOGOUT_MENU = 3;
const KEY_MY_BIDS_MENU = 2;
const KEY_HOME_MENU = 0;
const KEY_CURRENT_AUCTION_DASHBOARD = 1;
const KEY_ADMIN = 4;
// const KEY_CURRENT_AUCTION_MENU = 2;

export default function BottomNavComponent() {
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [nowTime, setNowTime] = React.useState();

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const currentAuction = useAuction();

  const handleLogout = () => {
    auth.signOut();
  };

  React.useEffect(() => {
    switch (value) {
      case KEY_HOME_MENU:
        navigate("/");
        break;

      case KEY_LOGOUT_MENU:
        handleLogout();
        break;

      case KEY_MY_BIDS_MENU:
        navigate("/my-bids");
        break;

      case KEY_CURRENT_AUCTION_DASHBOARD:
        navigate("/current-auction-table-view");
        break;

      case KEY_ADMIN:
        navigate("/settings");
        break;

      default:
        break;
    }
  }, [value]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(dayjs().unix());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    // When location changes outside of bottom nav items then set value to out of scope
    // So it can be brought back into scope of case statement for value useEffect
    setValue(99);
  }, [location.pathname]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Home" icon={<HomeOutlined />} />
      {/* <BottomNavigationAction label="Auction" icon={<GavelIcon />} /> */}
      <BottomNavigationAction label="Auction list" icon={<DashboardIcon />} />
      <BottomNavigationAction label="My bids" icon={<LocalAtmOutlined />} />
      <BottomNavigationAction label="Logout" icon={<LogoutIcon />} />
      {user?.uid === "odANpxBpWRYCkmksufBqkuzEdwv2" ? (
        <BottomNavigationAction label="Settings" icon={<Settings />} />
      ) : null}

      {nowTime > currentAuction.timestampEnd ? (
        <BottomNavigationAction
          label="Auction ENDED"
          icon={<AccessTime style={{ color: "red" }} />}
        />
      ) : null}
    </BottomNavigation>
  );
}
