import React, { useState, useEffect } from "react";
import CarComponent from "./CarComponent";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import moment from "moment";
import useVehicles from "./hooks/useVehicles";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import SearchComponent from "./SearchComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAllBids from "./hooks/useAllBids";
import BackdropComponent from "./BackdropComponent";

function AuctionListComponent() {
  const [filteredCars, setFilteredCars] = useState([]);

  const { bids } = useAllBids();
  const { vehicles, loading } = useVehicles();

  const clearFilter = () => {
    setFilteredCars(vehicles);
  };

  const filterHandle = (value) => {
    const stringToSearch = value;
    let tmpFilter = vehicles.filter((car) => {
      const carId = car.id;
      return carId.toLowerCase().indexOf(stringToSearch.toLowerCase()) >= 0;
    });

    // Sort by reverse
    tmpFilter.sort((a, b) => {
      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;
      return 0;
    });

    setFilteredCars(tmpFilter);
  };

  useEffect(() => {
    let tmpFilter = [...vehicles];
    tmpFilter.sort((a, b) => {
      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;
      return 0;
    });
    setFilteredCars(tmpFilter);
  }, [vehicles]);

  return (
    <>
      <BackdropComponent loading={loading} />
      <Grid
        container
        // spacing={1}
        direction="column"
        // justify="center"
        alignItems="center"
        style={{ backgroundColor: "#fafafa" }}
      >
        <Grid item style={{ marginBottom: 20, marginTop: 20 }}>
          <Typography variant="h5">Dutton Auction List</Typography>
        </Grid>
        <Grid item style={{ padding: 10 }}>
          <SearchComponent
            cars={vehicles}
            filterHandle={filterHandle}
            clearFilter={clearFilter}
          />
          <p>
            {loading
              ? null
              : `Showing ${filteredCars.length} of ${vehicles.length}`}
          </p>
        </Grid>
        {filteredCars.map((car, idx) => {
          return (
            <>
              <Grid item key={idx} style={{ width: "100%" }}>
                <CarComponent car={car} allBids={bids} />
              </Grid>
              <BlurOnIcon style={{ marginBottom: 10, marginTop: 10 }} />
            </>
          );
        })}
      </Grid>
      <div style={{ marginBottom: 75 }}></div>
    </>
  );
}

export default AuctionListComponent;
