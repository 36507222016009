export const firebaseConfig = {
  apiKey: "AIzaSyAZwJlhouvrdmoLKH3SQM7oo7_LMR6rOtg",
  authDomain: "dutton-auction.firebaseapp.com",
  databaseURL: "https://dutton-auction.firebaseio.com",
  projectId: "dutton-auction",
  storageBucket: "dutton-auction.appspot.com",
  messagingSenderId: "322018934820",
  appId: "1:322018934820:web:6c4e7f94216f8ab76bd8b1",
  measurementId: "G-5M5SDM8DFH",
};
