import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function AutoGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>The page you are looking for does not exist</h1>
          <h2>Please go back to the home page and try again</h2>
        </Grid>
        <Grid item xs={12}>
          <Link to="/">
            <Button variant="contained" color="secondary">
              Go to home page
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
