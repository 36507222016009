import { useState, useEffect } from "react";
import { useFirestore } from "reactfire";
import useAuction from "./useAuction";

function useTimeExtensions() {
  const [timeExtensions, setTimeExtension] = useState([{ extension: 0 }]);
  const [loading, setLoading] = useState(false);

  const db = useFirestore();
  const currentAuction = useAuction();

  useEffect(() => {
    if (currentAuction?.auctionId?.length) {
      setLoading(true);
      const unsubscribeCar = db
        .collection("auctions")
        .doc(currentAuction?.auctionId)
        .collection("timeExtensions")
        // .where("carId", "==", parseInt(carId))
        .onSnapshot(function (querySnapshot) {
          const tmp = [];
          querySnapshot.forEach(function (doc) {
            const d = doc.data();
            tmp.push({ id: doc.id, ...d });
          });

          setTimeExtension(tmp);
          setLoading(false);
        });

      return () => unsubscribeCar;
    }
  }, [JSON.stringify(currentAuction)]);

  return {
    timeExtensions,
    loading,
  };
}

export default useTimeExtensions;
