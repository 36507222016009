import React from "react";
import BottomNavComponent from "./BottomNavComponent";
import AuctionListComponent from "./AuctionListComponent";
import MyBidsComponent from "./MyBidsComponent";
import CreateNewAuctionComponent from "./CreateNewAuctionComponent";

function ProtectedMain() {
  return (
    <div>
      <AuctionListComponent />
      {/* <MyBidsComponent /> */}
      {/* <CreateNewAuctionComponent /> */}
      <BottomNavComponent />
    </div>
  );
}

export default ProtectedMain;
