import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "reactfire";
import useAuction from "./hooks/useAuction";

function WelcomeComponent() {
  const [nowTime, setNowTime] = React.useState();
  const user = useUser();
  const currentAuction = useAuction();
  const navigate = useNavigate();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(dayjs().format("dddd DD MMM YYYY HH:mm:ss"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h6">Welcome to Dutton Auctions</Typography>
        <br />
        {currentAuction.auctionEnd ? (
          <Alert variant="filled" color="error">
            This auction has ended
          </Alert>
        ) : null}

        <Table style={{ margin: 10 }}>
          <TableBody>
            <TableRow>
              <TableCell>Current auction unique identifier</TableCell>
              <TableCell>{currentAuction.auctionId || "Loading..."}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Auction starts</TableCell>
              <TableCell>
                {dayjs
                  .unix(currentAuction?.timestampStart)
                  .format("dddd DD MMM YYYY HH:mm:ss") || "Loading..."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Auction ends</TableCell>
              <TableCell>
                {dayjs
                  .unix(currentAuction?.timestampEnd)
                  .format("dddd DD MMM YYYY HH:mm:ss") || "Loading..."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Current time</TableCell>
              <TableCell>{nowTime}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Logged in as </TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Button
          onClick={() => navigate("/current-auction-table-view")}
          variant={currentAuction?.auctionEnd ? "outlined" : "contained"}
          color="primary"
        >
          View {currentAuction?.auctionEnd ? "past" : "current"} auction items
        </Button>

        <Grid style={{ marginTop: 25 }}>
          <Typography>
            This application is restricted for its intended audience only.
            Unauthorized access is forbidden by law.
          </Typography>
          <br />
          <Typography>
            Any bids submitted using this system are binding and the respective
            user will be required to complete any and all transactions related
            to the binding bid.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default WelcomeComponent;
