import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Stepper,
  StepLabel,
  Step,
  ButtonGroup,
  Chip,
  TextField,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { useFirestore, useUser } from "reactfire";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import firebase from "firebase";
import BackdropComponent from "./BackdropComponent";
import Moment from "react-moment";
import useAuction from "./hooks/useAuction";
import AlertSnackComponent from "./AlertSnackComponent";
import CardMedia from "@material-ui/core/CardMedia";
import blockUrl from "../blockImg.jpg";
import {
  AddCircleOutline,
  AttachMoneyOutlined,
  Keyboard,
  Timelapse,
  Check,
  ErrorOutline,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useRef } from "react";
import dayjs from "dayjs";
import useTimeExtension from "./hooks/useTimeExtension";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // width: "auto",
    margin: 5,
  },
  bidInput: {
    color: "#333",
    borderRadius: 3,
    padding: "1.2rem",
    backgroundColor: "#FFFFFF",
    // width: "100%",
    border: "1px solid #52BE80",

    // margin: 30,
    marginBottom: 10,
    fontSize: "1.5rem",
  },
  submitIcon: {
    color: "red",
    fontSize: "1.5em",
  },
  // closeButton: {
  //   position: "absolute",
  //   right: theme.spacing(1),
  //   top: theme.spacing(1),
  //   color: theme.palette.grey[500],
  // },
}));

const CustomTextField = React.forwardRef((props, ref) => (
  <TextField
    inputRef={ref}
    variant="outlined"
    color="primary"
    fullWidth
    {...props}
    InputProps={{
      ...props.InputProps,
      endAdornment: (
        <InputAdornment position="end">
          {props.valid ? (
            <Check style={{ color: "green" }} />
          ) : (
            <ErrorOutline style={{ color: "red" }} />
          )}
        </InputAdornment>
      ),
    }}
  />
));

export default function BidsDialogComponent({
  // bids,
  open,
  close,
  title,
  carId,
  carRego,
  car,
  allBids,
}) {
  const [bid, setBid] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [bidInScope, setBidInScope] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [alertMessage, setalertMessage] = React.useState("");
  const [currentUserIsHighestBidder, setCurrentUserIsHighestBidder] =
    React.useState(false);
  const [focusOnBidInput, setFocusOnBidInput] = React.useState(false);
  const [highestBid, setHighestBid] = React.useState([]);
  const [bids, setBids] = React.useState([]);
  const [ownCar, setOwnCar] = React.useState(false);
  const [isMember, setIsMember] = React.useState(false);
  const [canBid, setCanBid] = React.useState(false);
  const [isOverReserve, setIsOverReserve] = React.useState(false);
  const [timeExtensionInPlay, setTimeExtensionInPlay] = React.useState(false);
  const [timeExtensionInSeconds, setTimeExtensionInSeconds] = React.useState(0);

  const bidRef = useRef(null);
  // const bids = [{ bid: 86000, serverTime: moment().fromNow() }];

  const handleClose = () => {
    close();
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const classes = useStyles();

  const db = useFirestore();
  const user = useUser();
  const currentAuction = useAuction();
  const { timeExtensions } = useTimeExtension(carId);

  const getUserNameFromUid = (uid) => {
    const member = currentAuction?.members?.find((m) => m.uid === uid);
    return member?.userName || "";
  };

  const submitBid = async () => {
    if (!currentAuction.auctionStart) {
      setalertMessage(
        "Auction is not open yet, please wait before placing bids"
      );
      setOpenSnack(true);
      return;
    }
    if (currentAuction.auctionEnd && !timeExtensionInPlay) {
      setalertMessage("Auction is now closed, no more bids");
      setOpenSnack(true);
      return;
    }

    const nowTime = dayjs().unix();
    const extendedTo =
      currentAuction?.timestampEnd + timeExtensions?.length * 60 * 3;
    if (nowTime >= extendedTo) {
      setalertMessage("Auction is now closed, no more bids");
      setOpenSnack(true);
      return;
    }

    if (ownCar) {
      setalertMessage("Cannot bid on your own car");
      setOpenSnack(true);
      return;
    }
    if (!isMember) {
      setalertMessage(
        "Sorry, you need to be registered to place bids, please lodge a ticket with it@duttongroup.com.au to register"
      );
      setOpenSnack(true);
      return;
    }
    if (bid.length === 0) {
      setalertMessage("Please enter bid");
      setOpenSnack(true);
      return;
    } else if (!isOverReserve) {
      setalertMessage(`Bid must be higher than reserve`);
      setOpenSnack(true);
      return;
    } else if (!bidInScope) {
      setalertMessage("Bid must be higher than current highest bids");
      setOpenSnack(true);
      return;
    } else if (currentUserIsHighestBidder) {
      setalertMessage("You can't outbid yourself");
      setOpenSnack(true);
      return;
    } else if (parseInt(bid) - 250 < parseInt(highestBid.bid)) {
      setalertMessage("Bid must be at least a $250 interval of highest bid");
      setOpenSnack(true);
      return;
    } else if (checked) {
      setLoading(true);
      // Save to db
      const locTime = moment().toISOString();
      const locTimeEpoch = dayjs().unix();
      const serverTime = firebase.firestore.FieldValue.serverTimestamp();
      const auctionId = currentAuction.auctionId;
      if (auctionId === 0) {
        setalertMessage("No auction available to bid on ");
        setOpenSnack(true);
        return;
      }
      const heroImage =
        typeof car.thumbs[0] === "undefined" ? blockUrl : car.thumbs[0].src;

      const payload = {
        carRego,
        carId: parseInt(carId),
        bid,
        user: user.uid,
        userEmail: user.email,
        locTime,
        locTimeEpoch,
        serverTime,
        title,
        heroImage,
        // car,
        auctionId,
        lastHighestBid: highestBid,
        currentAuction: currentAuction,
      };

      await db
        .collection("auctions")
        .doc(auctionId)
        .collection("bids")
        .add(payload)
        .then((r) => {
          setalertMessage("Bid submitted");
          setOpenSnack(true);
          // close();
        })
        .catch((e) => {
          console.error(e);
        });
      setLoading(false);
    } else {
      setalertMessage("Please tick consent");
      setOpenSnack(true);
      return;
    }
  };

  // Set current bid scope when bid or highest bid changes
  // Set if current user is highest bidder
  React.useEffect(() => {
    if (highestBid.length === 0) {
      // No highest bid so ok to proceed
      setBidInScope(true);
    } else if (parseInt(bid) > parseInt(highestBid.bid)) {
      setBidInScope(true);
    } else {
      setBidInScope(false);
    }

    if (highestBid === false) {
      setCurrentUserIsHighestBidder(false);
    } else if (highestBid.user === user.uid) {
      setCurrentUserIsHighestBidder(true);
    } else {
      setCurrentUserIsHighestBidder(false);
    }
  }, [JSON.stringify(bid), JSON.stringify(highestBid), carId]);

  // Set bid input box focus
  React.useEffect(() => {
    if (focusOnBidInput) {
      bidRef.current.focus();
      bidRef.current.click();
      setFocusOnBidInput(false);
    }
  }, [focusOnBidInput]);

  // // Set bid is over reserve
  React.useEffect(() => {
    if (parseInt(bid) >= parseInt(car.reserve)) {
      setIsOverReserve(true);
    } else {
      setIsOverReserve(false);
    }
  }, [bid]);

  // Set highest bid from list of bids for car
  // Set vehicle bids
  React.useEffect(() => {
    if (allBids && allBids.length > 0) {
      // Filter car
      const carBids = allBids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      setBids(carBids);
      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        setHighestBid(carBids[0]);
      } else {
        setHighestBid([]);
      }
    }
  }, [JSON.stringify(bid), carId, JSON.stringify(allBids)]);

  // Set is member of auction
  React.useEffect(() => {
    if (currentAuction && user) {
      // Get current users set
      const matchUser = currentAuction.members?.find((m) => m.uid === user.uid);
      if (matchUser) {
        setIsMember(true);
      } else {
        setIsMember(false);
      }
    }
  }, [JSON.stringify(currentAuction), JSON.stringify(user)]);

  // Set flag if own car
  React.useEffect(() => {
    if (currentAuction && user && isMember) {
      // Get current users set
      const matchUser = currentAuction.members?.find((m) => m.uid === user.uid);
      if (matchUser) {
        if (matchUser.ownBranches?.includes(car.dealership)) {
          setOwnCar(true);
        } else {
          setOwnCar(false);
        }
      } else {
        setOwnCar(false);
      }
    } else {
      setOwnCar(false);
    }
  }, [JSON.stringify(currentAuction), user.uid, isMember, carId]);

  // Time extension
  React.useEffect(() => {
    if (timeExtensions.length > 0) {
      let tmpTE = false;
      const nowTime = dayjs().unix();
      const extendedTo =
        currentAuction.timestampEnd + timeExtensions.length * 60 * 3;
      if (nowTime < extendedTo) {
        setTimeExtensionInPlay(true);
        setTimeExtensionInSeconds(extendedTo);
      } else {
        setTimeExtensionInPlay(false);
        setTimeExtensionInSeconds(0);
      }
    } else {
      setTimeExtensionInPlay(false);
    }
  }, [JSON.stringify(timeExtensions)]);

  return (
    <>
      <BackdropComponent loading={loading} />
      <AlertSnackComponent
        alertMessage={alertMessage}
        openSnack={openSnack}
        closeSnack={() => setOpenSnack(false)}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        // maxWidth="md"
        scroll="body"
        onClose={handleClose}
        // aria-labelledby="alert-dialog-slide-title"
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {title} / {carRego}
          <br />
          Stock #{carId}
        </DialogTitle>
        <DialogContent>
          <Grid
          // container
          // spacing={2}
          // direction="column"
          // justify="center"
          // alignItems="center"
          >
            {timeExtensionInPlay ? (
              <Chip
                icon={<Timelapse fontSize="small" />}
                label={`Time extended to: ${dayjs
                  .unix(timeExtensionInSeconds)
                  .format("HH:mm:ss")}`}
                color="secondary"
                variant="outlined"
                style={{ marginBottom: 5 }}
              />
            ) : null}
            {currentUserIsHighestBidder ? (
              <>
                <Alert severity="error">
                  {currentAuction.auctionEnd && !timeExtensionInPlay
                    ? "You are the winner of this item"
                    : "You are currently the highest bidder"}
                </Alert>
                <br />
                <br />
              </>
            ) : null}
            {ownCar ? (
              <>
                <Alert severity="error">Cannot bid on your own car</Alert>
                <br />
              </>
            ) : null}

            {!isMember ? (
              <>
                <Alert severity="error">
                  You need to be a member of this auction to bid.
                  <br />
                  To register, please contact it@duttongroup.com.au
                </Alert>
                <br />
              </>
            ) : null}

            {!currentAuction?.auctionStart ? (
              <>
                <Alert severity="error">
                  Auction is not open yet, please wait before placing bids
                </Alert>
                <br />
              </>
            ) : null}

            <CardMedia
              component="img"
              key={`img11`}
              image={
                typeof car.thumbs[0] === "undefined"
                  ? blockUrl
                  : car.thumbs[0].src + "?crop=0"
              }
              title="hero"
              style={{
                maxHeight: 200,
                objectFit: "contain",
              }}
            />
            <br />
            {!currentAuction?.auctionEnd || timeExtensionInPlay ? (
              <FormControl
                fullWidth
                focused
                className="col"
                variant="outlined"
                margin="normal"
              >
                <NumberFormat
                  displayType="input"
                  customInput={CustomTextField}
                  type="tel"
                  valid={isOverReserve}
                  fullWidth
                  thousandSeparator={true}
                  prefix={"$"}
                  // className={classes.bidInput}
                  placeholder="Enter bid..."
                  onValueChange={(v) => setBid(v.floatValue)}
                  value={bid}
                  getInputRef={bidRef}
                />
              </FormControl>
            ) : null}
            {!isOverReserve ? (
              <Alert severity="error" variant="outlined" color="error">
                Bid needs to be over reserve of &nbsp;
                <NumberFormat
                  value={car.reserve}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              </Alert>
            ) : null}
            {(highestBid && !currentAuction.auctionEnd) ||
            timeExtensionInPlay ? (
              <>
                <Typography>Preset amounts over current highest bid</Typography>
                <ButtonGroup fullWidth variant="outlined">
                  <Button
                    fullWidth
                    onClick={() => setBid(500 + parseInt(highestBid.bid))}
                  >
                    <AddCircleOutline className={classes.submitIcon} />
                    $500
                  </Button>
                  <Button
                    fullWidth
                    onClick={() => setBid(1000 + parseInt(highestBid.bid))}
                  >
                    <AddCircleOutline className={classes.submitIcon} />
                    $1,000
                  </Button>
                  <Button
                    fullWidth
                    onClick={() => setBid(1500 + parseInt(highestBid.bid))}
                  >
                    <AddCircleOutline className={classes.submitIcon} />
                    $1,500
                  </Button>
                </ButtonGroup>
                <br />
                <br />
                <ButtonGroup fullWidth>
                  <Button onClick={() => setFocusOnBidInput(true)}>
                    <Keyboard className={classes.submitIcon} />
                    &nbsp; Custom
                  </Button>
                </ButtonGroup>
              </>
            ) : null}

            {!currentAuction.auctionEnd || timeExtensionInPlay ? (
              <>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position">
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          color="primary"
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="I consent to placing a binding bid"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
                <br />
                <Button
                  fullWidth
                  // disabled={currentUserIsHighestBidder}
                  variant="contained"
                  color="primary"
                  disabled={currentAuction?.auctionEnd && !timeExtensionInPlay}
                  onClick={submitBid}
                >
                  <AttachMoneyOutlined className={classes.submitIcon} />
                  &nbsp;{" "}
                  {currentUserIsHighestBidder
                    ? "Cannot outbid yourself"
                    : "Submit bid"}
                </Button>
              </>
            ) : null}
          </Grid>
          <br />
          {highestBid.length > 0 ? (
            <Alert severity="success">
              <i style={{ color: "green" }}>Highest bid:&nbsp;</i>
              <NumberFormat
                value={highestBid?.bid}
                displayType="text"
                thousandSeparator={true}
                prefix={"$"}
              />
              <Typography>
                {" "}
                by{" "}
                {highestBid?.user === user.uid ? "you" : highestBid?.userEmail}
              </Typography>
            </Alert>
          ) : null}

          <br />
          {bids.length > 0 ? (
            <Typography variant="subtitle1">
              Bid history - Last 5 bids
            </Typography>
          ) : (
            <Typography variant="subtitle1">
              No bid history available for this vehicle. Place the first bid.
            </Typography>
          )}
          <Stepper orientation="vertical" variant="dots">
            {bids.map((bid, idx) => {
              if (idx < 5) {
                return (
                  <Step key={idx}>
                    <StepLabel>
                      <NumberFormat
                        value={bid?.bid}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                      : <Moment date={bid?.locTime} fromNow />
                      <Typography>
                        {" "}
                        by{" "}
                        {bid?.user === user.uid
                          ? "Me"
                          : getUserNameFromUid(bid?.user)}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              } else {
                return null;
              }
            })}
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
