import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import useVehicles from "./hooks/useVehicles";
import useAllBids from "./hooks/useAllBids";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import BackdropComponent from "./BackdropComponent";
import { useUser } from "reactfire";
import { ListAltRounded, Timelapse } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import useAuction from "./hooks/useAuction";
import SearchComponent from "./SearchComponent";
import useTimeExtensions from "./hooks/useTimeExtensions";
import dayjs from "dayjs";

function AuctionTableComponent() {
  const [filteredCars, setFilteredCars] = useState([]);

  const { bids } = useAllBids();
  const { vehicles, loading } = useVehicles();
  const user = useUser();
  const navigate = useNavigate();
  const currentAuction = useAuction();
  const { timeExtensions } = useTimeExtensions();

  const clearFilter = () => {
    setFilteredCars(vehicles);
  };

  const filterHandle = (value) => {
    const stringToSearch = value;
    let tmpFilter = vehicles.filter((car) => {
      const carId = car.id;
      return carId.toLowerCase().indexOf(stringToSearch.toLowerCase()) >= 0;
    });

    // // Sort by reverse
    tmpFilter.sort((a, b) => {
      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;
      return 0;
    });

    setFilteredCars(tmpFilter);
  };

  const getHighestBid = (carId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return (
          <NumberFormat
            value={carBids[0].bid}
            displayType="text"
            thousandSeparator={true}
            prefix={"$"}
          />
        );
      } else {
        return "---";
      }
    }
  };

  const getUserNameFromUid = (uid) => {
    const member = currentAuction?.members?.find((m) => m.uid === uid);
    return member?.userName || "";
  };

  const getHighestBidder = (carId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return carBids[0].userEmail === user.email
          ? "Me"
          : getUserNameFromUid(carBids[0].user);
      } else {
        return "---";
      }
    }
  };

  const getHighestBidTime = (carId) => {
    if (bids) {
      // Filter car
      const carBids = bids?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      carBids.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (carBids.length > 0) {
        return <Moment date={carBids[0]?.locTime} fromNow />;
      } else {
        return "---";
      }
    }
  };

  const getTimeExtensions = (carId) => {
    if (timeExtensions) {
      // Filter car
      const te = timeExtensions?.filter((b) => {
        return parseInt(b.carId) === parseInt(carId);
      });

      // Sort by bid desc
      te.sort((a, b) => a.bid < b.bid);

      // Return highest
      if (te.length > 0) {
        const extendedTo = currentAuction?.timestampEnd + te?.length * 60 * 3;
        return (
          <Chip
            icon={<Timelapse fontSize="small" />}
            label={`Time extended to: ${dayjs
              .unix(extendedTo)
              .format("HH:mm:ss")}`}
            color="secondary"
            variant="outlined"
            style={{ marginBottom: 5 }}
          />
        );
      } else {
        return "---";
      }
    }
  };

  useEffect(() => {
    let tmpFilter = [...vehicles];
    tmpFilter.sort((a, b) => {
      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;
      return 0;
    });
    setFilteredCars(tmpFilter);
  }, [vehicles]);

  return (
    <>
      <BackdropComponent loading={loading} />

      <Grid
        container
        // spacing={1}
        direction="column"
        // justify="center"
        alignItems="center"
      >
        <Grid item style={{ marginBottom: 20, marginTop: 20 }}>
          <Typography variant="h5">Dutton Auction List</Typography>
        </Grid>
        {/* <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/current-auction-card-view")}
        >
          Switch to photo view
        </Button> */}
        <br />
        <SearchComponent
          cars={vehicles}
          filterHandle={filterHandle}
          clearFilter={clearFilter}
        />
        <p>
          {loading
            ? null
            : `Showing ${filteredCars.length} of ${vehicles.length}`}
        </p>
        <Paper sx={{ width: "100%", overflow: "scroll" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Stock#</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Odo</TableCell>
                <TableCell>Colour</TableCell>
                {/* <TableCell>Build Year</TableCell> */}
                <TableCell>Highest Bid</TableCell>
                <TableCell>Bidder</TableCell>
                <TableCell>Last bid @</TableCell>
                <TableCell>TE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCars.map((car, idx) => {
                return (
                  <TableRow
                    hover
                    key={idx}
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/item/${currentAuction.auctionId}/${car.id}`)
                    }
                  >
                    <TableCell>
                      <ListAltRounded
                        onClick={() =>
                          navigate(
                            `/item/${currentAuction.auctionId}/${car.id}`
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{car.id}</TableCell>
                    <TableCell>
                      {car.title}
                      <span style={{ color: "#52BE80" }}>
                        <i>
                          {car.thumbs?.length > 0
                            ? ` ${car.thumbs?.length} photos`
                            : null}
                        </i>
                      </span>
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={car.odo}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </TableCell>
                    <TableCell>{car.colour}</TableCell>
                    {/* <TableCell>{car.buildYear}</TableCell> */}
                    <TableCell>{getHighestBid(car.id)}</TableCell>
                    <TableCell>{getHighestBidder(car.id)}</TableCell>
                    <TableCell>{getHighestBidTime(car.id)}</TableCell>
                    <TableCell>{getTimeExtensions(car.id)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <div style={{ marginBottom: 75 }}></div>
    </>
  );
}

export default AuctionTableComponent;
